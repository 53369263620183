import { Box, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TransformIcon from '@mui/icons-material/Transform';
import DownloadIcon from '@mui/icons-material/Download';

export default function CompanyHeader() {
    const companyId = window.location.pathname.split('/')[2];
    const [activeTab, setActiveTab] = useState('mappings');
    const navigate = useNavigate();
    const location = useLocation();
    const [companyName, setCompanyName] = useState<string>(location.state?.companyName);

    const USERS_LINK = `/companies/${companyId}/users`;
    const MAPPING_LINK = `/companies/${companyId}/mappings`;
    const BULKS_LINK = `/companies/${companyId}/bulks`;
    const HOME_LINK = '/';

    useEffect(() => {
        if (!companyName) {
            const newCompany = localStorage.getItem('companyName');
            setCompanyName(newCompany || '');
        }
    }, [companyName]);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1000,
                bgcolor: 'background.paper',
                p: 1,
                boxShadow: 1,
            }}
        >
            <button
                onClick={() => {
                    navigate(HOME_LINK);
                }}
                style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
            >
                <Typography
                    variant="h6"
                    sx={{
                        color: 'primary.main',
                    }}
                >
                    Companies
                </Typography>
            </button>
            <Typography
                variant="h3"
                sx={{ display: 'flex', justifyContent: 'center', fontWeight: 600, color: '#1e293b' }}
            >
                {companyName}
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2, pb: 1 }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <button
                            onClick={() => {
                                setActiveTab('mappings');
                                navigate(MAPPING_LINK);
                            }}
                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: activeTab === 'mappings' ? 'primary.main' : 'textSecondary',
                                    fontWeight: activeTab === 'mappings' ? 'bold' : 'normal',
                                    borderBottom: activeTab === 'mappings' ? '2px solid blue' : 'none',
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <TransformIcon />
                                Mappings
                            </Typography>
                        </button>
                    </Grid>
                    <Grid item>
                        <button
                            onClick={() => {
                                setActiveTab('users');
                                navigate(USERS_LINK);
                            }}
                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: activeTab === 'users' ? 'primary.main' : 'textSecondary',
                                    fontWeight: activeTab === 'users' ? 'bold' : 'normal',
                                    borderBottom: activeTab === 'users' ? '2px solid blue' : 'none',
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <PeopleAltIcon />
                                Users
                            </Typography>
                        </button>
                    </Grid>
                    <Grid item>
                        <button
                            onClick={() => {
                                setActiveTab('bulks');
                                navigate(BULKS_LINK);
                            }}
                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    color: activeTab === 'bulks' ? 'primary.main' : 'textSecondary',
                                    fontWeight: activeTab === 'bulks' ? 'bold' : 'normal',
                                    borderBottom: activeTab === 'bulks' ? '2px solid blue' : 'none',
                                    textDecoration: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <DownloadIcon />
                                Bulks
                            </Typography>
                        </button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
