import { get, post, put, remove } from '../../../global/connectors/BridgeAPIConnector';
import {
    FieldAPIRepresentation,
    FieldDTO,
    MappingDTO,
    TemplateAPIRepresentation,
    TemplateDTO,
} from '../../../types/MappingDTO';

export async function getAllTemplates(): Promise<TemplateDTO[]> {
    try {
        const results = await get('/mapping-templates');
        const templates: TemplateDTO[] = [];

        if (Array.isArray(results)) {
            results.forEach((result: TemplateAPIRepresentation) => {
                templates.push(mapTemplateApiToDto(result));
            });
        } else if (results && results.items && Array.isArray(results.items)) {
            results.items.forEach((result: TemplateAPIRepresentation) => {
                templates.push(mapTemplateApiToDto(result));
            });
        } else {
            console.warn('Template API response has unexpected format:', results);
        }

        return templates;
    } catch (error) {
        console.error('Error fetching templates:', error);
        return [];
    }
}

export async function getTemplateByName(templateName: string): Promise<TemplateDTO | null> {
    try {
        const result = await get(`/mapping-templates/${templateName}`);
        return mapTemplateApiToDto(result);
    } catch (error) {
        console.error('Error fetching template:', error);
        return null;
    }
}

export async function createTemplate(template: TemplateDTO): Promise<TemplateDTO> {
    try {
        const apiTemplate = mapTemplateDtoToApi(template);

        const result = await post('/mapping-templates', apiTemplate);

        return mapTemplateApiToDto(result);
    } catch (error) {
        console.error('Error creating template:', error);
        throw error;
    }
}

export async function updateTemplate(templateName: string, template: TemplateDTO): Promise<TemplateDTO> {
    try {
        const apiTemplate = mapTemplateDtoToApi(template);

        const result = await put(`/mapping-templates/${templateName}`, apiTemplate);

        return mapTemplateApiToDto(result);
    } catch (error) {
        console.error('Error updating template:', error);
        throw error;
    }
}

export async function deleteTemplate(templateName: string): Promise<void> {
    await remove(`/mapping-templates/${templateName}`);
}

export async function createMappingFromTemplate(
    companyId: string,
    badgerObjectName: string,
    crmObjectName: string,
    templateName: string
): Promise<MappingDTO> {
    const data = {
        badger_object_name: badgerObjectName,
        crm_object_name: crmObjectName,
        template_name: templateName,
    };

    const result = await post(`/companies/${companyId}/mappings/from-template`, data);

    return {
        id: result.id,
        badgerName: result.badger_object_name,
        crmName: result.crm_object_name,
        fields: result.fields?.map((f: FieldAPIRepresentation) => ({
            crmField: { key: f.crm_field.key },
            badgerField: { key: f.badger_field.key },
            type: f.type,
        })),
        customJS: result.custom_js,
    };
}

function mapTemplateApiToDto(api: TemplateAPIRepresentation): TemplateDTO {
    if (!api) {
        console.warn('Received undefined or null API template data');
        return {
            templateName: '',
            fields: [],
            customJS: '',
        };
    }

    const fields: FieldDTO[] = Array.isArray(api.fields)
        ? api.fields.map((field) => ({
              crmField: { key: field.crm_field?.key || '' },
              badgerField: { key: field.badger_field?.key || '' },
              type: field.type || 'string',
          }))
        : [];

    return {
        id: api.id,
        templateName: api.template_name || '',
        fields: fields,
        customJS: api.custom_js || '',
    };
}

function mapTemplateDtoToApi(dto: TemplateDTO): {
    template_name: string;
    fields: { crm_field: { key: string }; badger_field: { key: string }; type: string }[];
    custom_js: string;
} {
    if (!dto) {
        console.warn('Received undefined or null DTO template data');
        return {
            template_name: '',
            fields: [],
            custom_js: '',
        };
    }

    const fields = Array.isArray(dto.fields)
        ? dto.fields.map((field) => ({
              crm_field: field.crmField || { key: '' },
              badger_field: field.badgerField || { key: '' },
              type: field.type || 'string',
          }))
        : [];

    return {
        template_name: dto.templateName || '',
        fields: fields,
        custom_js: dto.customJS || '',
    };
}
