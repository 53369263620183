import { Box, Button, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import { getAllCompanies } from './repositories/CompaniesRepository';
import NewCompanyForm from './components/NewCompanyForm';
import { enqueueSnackbar } from 'notistack';
import { CompanyDTO } from '../../types/Company';
import EditCompanyForm from './components/EditCompanyForm';
import SearchIcon from '@mui/icons-material/Search';
import { colorTokens } from '../../theme';
import ColoredBox from './components/ColoredBox';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState<CompanyDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [companyToEdit, setCompanyToEdit] = useState<CompanyDTO | null>(null);
    const [searchQuery, setSearchQuery] = useState('');

    type Row = (typeof companies)[number];

    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
            getAllCompanies().then((data) => {
                setCompanies(data);
                setIsLoading(false);
            });
        }
    }, [isLoading]);

    const onFormClose = () => {
        setIsFormOpen(false);
        refreshCompanies();
    };

    const refreshCompanies = () => {
        setIsLoading(true);
        getAllCompanies().then((data) => {
            setCompanies(data);
            setIsLoading(false);
        });
    };

    const onEditFormClose = () => {
        setIsEditFormOpen(false);
    };

    const handleEdit = (company: CompanyDTO) => {
        setCompanyToEdit(company);
        setIsEditFormOpen(true);
    };

    const copyIDToClipboard = (value: string) => {
        navigator.clipboard.writeText(value).then(() => console.log('Copied to clipboard.'));
        enqueueSnackbar('Copied successfully', { variant: 'success' });
    };

    const filteredCompanies = companies.filter((company) =>
        company.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const crmColors: { [key: string]: string } = {
        SALESFORCE: 'rgba(0, 161, 224, 0.7)',
        HUBSPOT: 'rgba(255, 122, 89, 0.7)',
        ZOHO: 'rgba(0, 114, 198, 0.7)',
        NETSUITE: 'rgba(255, 165, 0, 0.7)',
        INSIGHTLY: 'rgba(242, 113, 28, 0.7)',
        DYNAMICS: 'rgba(0, 120, 212, 0.7)',
    };

    const environmentColors = {
        PRODUCTION: 'rgba(76, 175, 80, 0.7)',
        SANDBOX: 'rgba(128, 128, 128, 0.7)',
    };

    const columns: GridColDef<Row>[] = [
        {
            field: 'name',
            headerName: 'Company Name',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box
                    component="a"
                    onClick={() => {
                        navigate(`/companies/${params.row.id}/mappings`, { state: { companyName: params.row.name } });
                    }}
                    sx={{
                        color: '#1e293b',
                        fontSize: '0.9rem',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 16px',
                        margin: '0 -16px',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        },
                    }}
                >
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.25,
            headerAlign: 'center',
            renderCell: (params: GridRenderCellParams) => (
                <Tooltip title="Copy ID" arrow>
                    <IconButton
                        size="small"
                        onClick={() => copyIDToClipboard(params.value)}
                        sx={{
                            color: '#94a3b8',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <ContentCopyIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            field: 'crm',
            headerName: 'CRM',
            flex: 0.5,
            headerAlign: 'center',
            renderCell: (params: GridRenderCellParams) => {
                const backgroundColor = crmColors[params.value as keyof typeof crmColors] || colorTokens.grey[500];
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <ColoredBox value={params.value} backgroundColor={backgroundColor} />
                    </Box>
                );
            },
        },
        {
            field: 'environment',
            headerName: 'Environment',
            flex: 0.5,
            headerAlign: 'center',
            renderCell: (params: GridRenderCellParams) => {
                const backgroundColor =
                    environmentColors[params.value as keyof typeof environmentColors] || colorTokens.grey[500];
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <ColoredBox value={params.value} backgroundColor={backgroundColor} />
                    </Box>
                );
            },
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.25,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: GridRenderCellParams) => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <Tooltip title="Edit Company Details" arrow>
                        <IconButton
                            size="small"
                            onClick={() => handleEdit(params.row)}
                            sx={{
                                color: '#94a3b8',
                                '&:hover': {
                                    color: '#475569',
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{ p: 3, pb: 5, px: 10 }}>
            <NewCompanyForm isOpen={isFormOpen} onClose={onFormClose} />
            {isEditFormOpen && companyToEdit && (
                <EditCompanyForm
                    company={companyToEdit}
                    isOpen={isEditFormOpen}
                    onClose={onEditFormClose}
                    refreshCompanies={refreshCompanies}
                />
            )}

            <Box sx={{ mb: 3 }}>
                <Typography
                    variant="h3"
                    sx={{ display: 'flex', justifyContent: 'center', fontWeight: 600, color: '#1e293b', mb: 1 }}
                >
                    Companies
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Box /> {/* Empty Box to take up space on the left */}
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setIsFormOpen(true)}
                        sx={{
                            backgroundColor: colorTokens.primary[500],
                            textTransform: 'none',
                            borderRadius: '8px',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#1d4ed8',
                            },
                        }}
                    >
                        Add company
                    </Button>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                    <TextField
                        placeholder="Search companies by Name"
                        variant="outlined"
                        size="small"
                        sx={{
                            flex: 1,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                backgroundColor: 'white',
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: '#94a3b8' }} />
                                </InputAdornment>
                            ),
                        }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </Box>
            </Box>

            <Paper
                sx={{
                    borderRadius: '12px',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                    mb: 3,
                    height: 'calc(100vh - 220px)',
                    overflow: 'auto',
                }}
            >
                <DataGrid
                    rows={filteredCompanies}
                    columns={columns}
                    autoHeight={false}
                    sx={{
                        border: 'none',
                        p: 2,
                        pb: 5,
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f8fafc',
                            borderBottom: '1px solid #e2e8f0',
                        },
                        '& .MuiDataGrid-cell': {
                            borderColor: '#f1f5f9',
                            padding: '10px',
                        },
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: '#f8fafc',
                        },
                    }}
                    disableRowSelectionOnClick
                    pageSizeOptions={[20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 100 } },
                    }}
                />
            </Paper>
        </Box>
    );
};

export default Home;
