import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Skeleton } from '@mui/material';
import EventCard from './components/EventCard';
import AddEventDialog from './components/AddEventDialog';
import { EventDTO } from '../../types/Events';
import { MappingDTO } from '../../types/MappingDTO';
import { getAllMappings } from '../mapping/repositories/mappingRepository';
import { getEvents } from './repositories/EventsRepository';
import AddIcon from '@mui/icons-material/Add';
import { getCompanyByID } from '../company/repositories/companyRepository';

const EventsPage = () => {
    const { userId, companyId } = useParams() as { userId: string; companyId: string };
    const [events, setEvents] = useState<EventDTO[]>([]);
    const [mappings, setMappings] = useState<MappingDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFormOpen, setIsFormOpen] = useState(false);

    const refreshEvents = useCallback(() => {
        setIsLoading(true);
        Promise.all([getEvents(userId), getAllMappings(companyId)]).then(([eventsData, mappingData]) => {
            setEvents(eventsData);
            setMappings(mappingData);
            setIsLoading(false);
        });
    }, [companyId, userId]);

    const fetchCompany = async () => {
        if (companyId !== localStorage.getItem('companyID')) {
            const newCompany = await getCompanyByID(companyId);
            localStorage.setItem('companyName', newCompany?.name || '');
            localStorage.setItem('companyID', newCompany?.id || '');
        }
    };

    useEffect(() => {
        if (isLoading) {
            fetchCompany();
            refreshEvents();
        }
    }, [userId, isLoading, refreshEvents]);

    const onFormClose = () => {
        setIsFormOpen(false);
        refreshEvents();
    };

    if (isLoading) {
        return <Skeleton variant="rectangular" width="100%" height="100%" />;
    }

    return (
        <Box width="100%" height="100%" margin="100px" sx={{ p: 6 }}>
            {isFormOpen && (
                <AddEventDialog open={isFormOpen} onClose={onFormClose} onNewEvent={onFormClose} mappings={mappings} />
            )}
            <Button onClick={() => setIsFormOpen(true)} variant="contained" sx={{ m: 2 }} startIcon={<AddIcon />}>
                Set up a new event config
            </Button>
            <Grid container spacing={6}>
                {events.map((event) => {
                    return (
                        <Grid item>
                            <EventCard
                                event={event}
                                mappingName={
                                    mappings.find((mapping) => mapping.id === event.mappingId)?.badgerName || ''
                                }
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default EventsPage;
