import { Backdrop, Box, Button, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../users/repositories/userRepository';
import { UserDTO } from '../../types/UserDTO';
import { findUserIdByUsername } from '../../utils/userUtils';
import { fetchData } from '../../services/recordService';
import './TryOut.css';
import { Editor } from '@monaco-editor/react';

interface TryOutComponentProps {
    companyId: string;
    badgerName: string;
    crmName: string;
    mappingId: string;
}

const TryOutComponent = (props: TryOutComponentProps) => {
    const { companyId, badgerName, crmName, mappingId } = props;
    const [users, setUsers] = useState<UserDTO[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>('');
    type MethodType = 'GET' | 'GET By ID' | 'DELETE' | 'GET Recently Modified Records' | 'GET Recently Deleted Records';
    const [selectedMethod, setSelectedMethod] = useState<MethodType>('GET');
    const METHODS = ['GET', 'GET By ID', 'DELETE', 'GET Recently Modified Records', 'GET Recently Deleted Records'];
    const BATCH_DEFAULT_SIZE = '5';
    const [batchSize, setBatchSize] = useState<string>(BATCH_DEFAULT_SIZE);
    const [loader, setLoader] = useState<boolean>(false);
    const [rawData, setRawData] = useState<string>('');
    const [transformedData, setTransformedData] = useState<string>('');
    const [crmObjectID, setCrmObjectID] = useState<string>('');

    useEffect(() => {
        getAllUsers(companyId).then(setUsers);
    }, [companyId]);

    const handleButtonClick = async () => {
        const userId = findUserIdByUsername(users, selectedUser);

        if (!userId) {
            return;
        }

        setLoader(true);
        const { rawPayload, transformedPayload } = await fetchData(
            selectedMethod,
            userId,
            badgerName,
            crmName,
            batchSize,
            crmObjectID,
            mappingId
        );

        setRawData(JSON.stringify(rawPayload, null, 2));
        setTransformedData(JSON.stringify(transformedPayload, null, 2));
        setLoader(false);
    };

    return (
        <Box>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <TextField
                key="user-select"
                label="User"
                select
                variant="outlined"
                margin="dense"
                fullWidth
                size="small"
                value={selectedUser}
                required={true}
                onChange={(event) => setSelectedUser(event.target.value)}
            >
                {users.map((user) => {
                    return (
                        <MenuItem key={user.id} value={user.username}>
                            {user.username}
                        </MenuItem>
                    );
                })}
            </TextField>
            <TextField
                key="method-select"
                label="Method"
                select
                variant="outlined"
                margin="dense"
                fullWidth
                size="small"
                value={selectedMethod}
                onChange={(event) => setSelectedMethod(event.target.value as MethodType)}
            >
                {METHODS.map((method) => {
                    return (
                        <MenuItem key={method} value={method}>
                            {method}
                        </MenuItem>
                    );
                })}
            </TextField>

            {selectedMethod === 'GET' && (
                <TextField
                    key="batch-size"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    size="small"
                    onChange={(event) => setBatchSize(event.target.value)}
                    label="Batch size"
                    type="number"
                    value={batchSize}
                />
            )}
            {(selectedMethod === 'GET By ID' || selectedMethod === 'DELETE') && (
                <TextField
                    key="crm-object-id"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    size="small"
                    onChange={(event) => setCrmObjectID(event.target.value)}
                    label="ID"
                    type="string"
                    value={crmObjectID}
                />
            )}
            <Button key="tryoutbutton" variant="contained" color="primary" onClick={handleButtonClick}>
                Try out
            </Button>
            {(rawData || transformedData) && (
                <Box className="cardJson" display="flex" flexDirection="row" justifyContent="space-between">
                    {transformedData && (
                        <Box flex={1} key="boxjson1">
                            <Typography variant="subtitle1" gutterBottom align="center">
                                Transformed Data
                            </Typography>
                            <Box className="jsonBlockOuter" flex={1}>
                                <Editor
                                    height="600px"
                                    value={transformedData}
                                    options={{
                                        readOnly: true,
                                        scrollbar: {
                                            vertical: 'visible',
                                            horizontal: 'visible',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                    {rawData && (
                        <Box flex={1} key="boxjson2">
                            <Typography variant="subtitle1" gutterBottom align="center">
                                Raw Data
                            </Typography>
                            <Box className="jsonBlockOuter" flex={1}>
                                <Editor
                                    height="600px"
                                    value={rawData}
                                    options={{
                                        readOnly: true,
                                        scrollbar: {
                                            vertical: 'visible',
                                            horizontal: 'visible',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default TryOutComponent;
