import { MethodMappingType } from '../types/RecordDTO';
import {
    deleteRawRecordByID,
    getRawRecordByID,
    getRawRecordsByObjectName,
    getTransformedRecordByID,
    getTransformedRecordsByObjectName,
} from '../pages/records/repositories/recordsRepository';
import {
    getRecentlyModifiedRawRecords,
    getRecentlyDeletedRawRecords,
    getRecentlyModifiedTransformedRecords,
    getEvents,
} from '../pages/events/repositories/EventsRepository';

export const fetchData = async (
    selectedMethod: keyof MethodMappingType,
    userId: string,
    badgerName: string,
    crmName: string,
    batchSize: string,
    crmObjectID: string,
    mappingId: string
) => {
    const selectedMethodFunctions = methodMapping[selectedMethod];
    let rawPayload, transformedPayload;

    switch (selectedMethod) {
        case 'GET': {
            rawPayload = await selectedMethodFunctions.raw(userId, crmName, batchSize);
            transformedPayload = await selectedMethodFunctions.transformed(userId, badgerName, batchSize);
            break;
        }
        case 'GET By ID': {
            rawPayload = await selectedMethodFunctions.raw(userId, crmName, crmObjectID);
            transformedPayload = await selectedMethodFunctions.transformed(userId, badgerName, crmObjectID);
            break;
        }
        case 'DELETE': {
            rawPayload = await selectedMethodFunctions.raw(userId, crmName, crmObjectID);
            transformedPayload = {};
            break;
        }
        case 'GET Recently Modified Records': {
            const eventConfigurations = await getEvents(userId);
            const eventConfigurationId = eventConfigurations.find((event) => event.mappingId === mappingId)?.id;
            if (!eventConfigurationId) {
                rawPayload = { message: 'Event configuration not found for this user and mapping.' };
                transformedPayload = { message: 'Event configuration not found for this user and mapping.' };
            } else {
                rawPayload = await methodMapping['GET Recently Modified Records'].raw(eventConfigurationId);
                transformedPayload =
                    await methodMapping['GET Recently Modified Records'].transformed(eventConfigurationId);
            }
            break;
        }
        case 'GET Recently Deleted Records': {
            const deletedEventConfigurations = await getEvents(userId);
            const deletedEventConfigurationId = deletedEventConfigurations.find(
                (event) => event.mappingId === mappingId
            )?.id;
            if (!deletedEventConfigurationId) {
                rawPayload = { message: 'Event configuration not found for this user and mapping.' };
                transformedPayload = { message: 'Event configuration not found for this user and mapping.' };
            } else {
                rawPayload = await methodMapping['GET Recently Deleted Records'].raw(deletedEventConfigurationId);
                transformedPayload = {};
            }
            break;
        }
    }

    return { rawPayload, transformedPayload };
};

const methodMapping: MethodMappingType = {
    GET: {
        raw: getRawRecordsByObjectName,
        transformed: getTransformedRecordsByObjectName,
    },
    'GET By ID': {
        raw: getRawRecordByID,
        transformed: getTransformedRecordByID,
    },
    DELETE: {
        raw: deleteRawRecordByID,
        transformed: deleteRawRecordByID,
    },
    'GET Recently Modified Records': {
        raw: getRecentlyModifiedRawRecords,
        transformed: getRecentlyModifiedTransformedRecords,
    },
    'GET Recently Deleted Records': {
        raw: getRecentlyDeletedRawRecords,
        transformed: getRecentlyDeletedRawRecords,
    },
};
