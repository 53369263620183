import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MappingDTO } from '../../types/MappingDTO';
import { getCompanyByID } from '../company/repositories/companyRepository';
import AddMappingCard from './components/AddMappingCard';
import AddMappingDialog from './components/AddMappingDialog';
import MappingCard from './components/MappingCard';
import TemplateManagementDialog from './components/TemplateManagementDialog';
import { getAllMappings } from './repositories/mappingRepository';

const Mapping = () => {
    const { companyId } = useParams() as { companyId: string };
    const [mappings, setMappings] = useState<MappingDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNewMappingOpen, setIsNewMappingOpen] = useState(false);
    const [isTemplateManagementOpen, setIsTemplateManagementOpen] = useState(false);

    const refreshMappings = React.useCallback(() => {
        setIsLoading(true);
        getAllMappings(companyId).then((data) => {
            data.sort((a, b) => (a.badgerName > b.badgerName || b.badgerName === 'customers' ? 1 : -1));
            setMappings(data);
            setIsLoading(false);
        });
    }, [companyId]);

    const fetchCompany = async () => {
        if (companyId !== localStorage.getItem('companyID')) {
            const newCompany = await getCompanyByID(companyId);
            localStorage.setItem('companyName', newCompany?.name || '');
            localStorage.setItem('companyID', newCompany?.id || '');
        }
    };

    useEffect(() => {
        if (isLoading) {
            fetchCompany();
            refreshMappings();
        }
    }, [companyId, isLoading, refreshMappings]);

    const onNewMapping = () => {
        setIsNewMappingOpen(false);
        refreshMappings();
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box width="100%" height="100%" sx={{ p: 6, mt: '140px' }}>
            {isNewMappingOpen && (
                <AddMappingDialog
                    open={isNewMappingOpen}
                    onClose={() => setIsNewMappingOpen(false)}
                    onNewMapping={onNewMapping}
                />
            )}

            {isTemplateManagementOpen && (
                <TemplateManagementDialog
                    open={isTemplateManagementOpen}
                    onClose={() => setIsTemplateManagementOpen(false)}
                />
            )}

            <Box display="flex" flexDirection="column" mb={4}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsTemplateManagementOpen(true)}
                    sx={{
                        alignSelf: 'flex-start',
                        mb: 2,
                        backgroundColor: 'white',
                    }}
                >
                    Manage Templates
                </Button>

                <Typography variant="h4">Object Mappings</Typography>
            </Box>

            <Grid container spacing={6}>
                {mappings.map((mapping) => {
                    if (!mapping.id) {
                        return null;
                    }
                    return (
                        <Grid item key={'Grid' + mapping.id}>
                            <MappingCard
                                key={mapping.id}
                                badgerName={mapping.badgerName}
                                crmName={mapping.crmName}
                                mappingId={mapping.id}
                            />
                        </Grid>
                    );
                })}
                <Grid item>
                    <AddMappingCard onClick={() => setIsNewMappingOpen(true)} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Mapping;
