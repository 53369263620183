import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { CompanyDTO } from '../../../types/Company';
import { updateCompany } from '../../company/repositories/companyRepository';

interface EditCompanyFormProps {
    company: CompanyDTO;
    isOpen: boolean;
    onClose: () => void;
    refreshCompanies: () => void;
}

const EditCompanyForm: React.FC<EditCompanyFormProps> = ({ company, isOpen, onClose, refreshCompanies }) => {
    const [formData, setFormData] = React.useState({
        login_url: company.login_url,
        api_url: company.api_url,
        environment: company.environment,
    });

    async function handleSave(event: React.FormEvent) {
        event.preventDefault();
        const updatedCompany = { ...company, ...formData };
        updateCompany(updatedCompany)
            .then(refreshCompanies)
            .catch((error) => console.error(error));
        onClose();
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Edit Company</DialogTitle>
            <form autoComplete="off" onSubmit={handleSave}>
                <DialogContent sx={{ width: '600px' }}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Select
                                label="Environment"
                                id="environment"
                                value={formData.environment}
                                onChange={(e) => setFormData({ ...formData, environment: e.target.value })}
                                fullWidth
                            >
                                <MenuItem value="PRODUCTION">Production</MenuItem>
                                <MenuItem value="SANDBOX">Sandbox</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Login URL"
                                id="login_url"
                                value={formData.login_url}
                                onChange={(e) => setFormData({ ...formData, login_url: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="API URL"
                                id="api_url"
                                value={formData.api_url}
                                onChange={(e) => setFormData({ ...formData, api_url: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EditCompanyForm;
