import React from 'react';
import { Box } from '@mui/material';

interface ColoredBoxProps {
    value: string;
    backgroundColor: string;
}

const ColoredBox: React.FC<ColoredBoxProps> = ({ value, backgroundColor }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50%',
                height: '100%',
                backgroundColor: backgroundColor,
                borderRadius: '12px',
                padding: '4px 8px',
                color: 'white',
            }}
        >
            {value.toLowerCase()}
        </Box>
    );
};

export default ColoredBox;
