import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FieldDTO } from '../../../types/MappingDTO';
import { createTemplate } from '../../mapping/repositories/templateRepository';

interface SaveAsTemplateDialogProps {
    open: boolean;
    onClose: () => void;
    fields: FieldDTO[];
    customJS: string;
}

const SaveAsTemplateDialog: React.FC<SaveAsTemplateDialogProps> = ({ open, onClose, fields, customJS }) => {
    const [templateName, setTemplateName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSave = async () => {
        if (!templateName.trim()) {
            setError('Template name is required');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            await createTemplate({
                templateName: templateName.trim(),
                fields: fields,
                customJS,
            });

            enqueueSnackbar(`Template "${templateName}" saved successfully`, {
                variant: 'success',
                autoHideDuration: 3000,
            });

            setTemplateName('');
            onClose();
        } catch (error) {
            console.error('Error saving template:', error);
            setError('Failed to save template. Please try again.');
            enqueueSnackbar('Failed to save template', {
                variant: 'error',
                autoHideDuration: 3000,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Save as Template</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="templateName"
                    label="Template Name"
                    fullWidth
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    error={!!error}
                    helperText={error}
                    disabled={isLoading}
                    required
                />

                {fields.length > 0 && (
                    <Box mt={3}>
                        <Typography variant="subtitle1">
                            This template will include {fields.length} field{fields.length !== 1 ? 's' : ''}:
                        </Typography>
                        <Paper variant="outlined" sx={{ mt: 1, maxHeight: 200, overflow: 'auto' }}>
                            <List dense>
                                {fields.map((field, index) => (
                                    <React.Fragment key={field.id || index}>
                                        {index > 0 && <Divider />}
                                        <ListItem>
                                            <ListItemText
                                                primary={`${field.crmField.key} → ${field.badgerField.key}`}
                                                secondary={`Type: ${field.type}`}
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Paper>
                    </Box>
                )}

                {customJS && (
                    <Box mt={3}>
                        <Typography variant="subtitle1">Custom JavaScript will be included</Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isLoading}>
                    Cancel
                </Button>
                <LoadingButton onClick={handleSave} loading={isLoading} variant="contained" color="primary">
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SaveAsTemplateDialog;
