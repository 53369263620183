import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarQuickFilter } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { getAllBulks, getRawBulkUrl, getTransformedBulkUrl } from './BulksRepository';
import { getAllUsers } from './BulksRepository';
import { BulkDTO } from '../../types/Bulk';
import { UserDTO } from '../../types/UserDTO';
import NewBulkDialog from './components/NewBulkDialog';
import DownloadIcon from '@mui/icons-material/Download';
import { getCompanyByID } from '../company/repositories/companyRepository';
import { getAllMappings } from '../mapping/repositories/mappingRepository';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

const BulksPage = () => {
    const { companyId } = useParams() as { companyId: string };
    const [bulks, setBulks] = useState<BulkDTO[]>([]);
    const [users, setUsers] = useState<UserDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingButtonId, setLoadingButtonId] = useState<string | null>(null);
    const [loadingButton, setLoadingButton] = useState<string | null>(null);
    const [isNewBulkOpen, setIsNewBulkOpen] = useState(false);
    type Row = (typeof bulks)[number];
    const [downloadUrl, setDownloadUrl] = useState<string>('');
    const [rawUrl, setRawUrl] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');

    const refreshBulks = React.useCallback(() => {
        setIsLoading(true);
        Promise.all([getAllBulks(companyId), getAllUsers(companyId), getAllMappings(companyId)]).then(
            ([receivedBulks, receivedUsers, receivedMappings]) => {
                const mappingLookup = new Map(receivedMappings.map((mapping) => [mapping?.id, mapping]));
                const bulksWithNames = receivedBulks.map((bulk) => {
                    const correspondingMapping = mappingLookup.get(bulk?.mappingId);
                    return {
                        ...bulk,
                        badgerObjectName: correspondingMapping?.badgerName || 'Unknown',
                        crmObjectName: correspondingMapping?.crmName || 'Unknown',
                    };
                });

                setBulks(bulksWithNames);
                setUsers(receivedUsers);
                setIsLoading(false);
            }
        );
    }, [companyId]);

    const fetchCompany = async () => {
        if (companyId !== localStorage.getItem('companyID')) {
            const newCompany = await getCompanyByID(companyId);
            localStorage.setItem('companyName', newCompany?.name || '');
            localStorage.setItem('companyID', newCompany?.id || '');
        }
    };

    useEffect(() => {
        if (isLoading) {
            fetchCompany();
            refreshBulks();
        }
    }, [companyId, isLoading, refreshBulks]);

    const onNewBulkClose = () => {
        setIsNewBulkOpen(false);
        refreshBulks();
    };

    const copyIDToClipboard = (value: string) => {
        navigator.clipboard.writeText(value).then(() => console.log('Copied to clipboard'));
        enqueueSnackbar('Copied successfully', { variant: 'success' });
    };

    const setTransformedDownloadUrl = (bulkId: string, userId: string) => {
        setLoadingButtonId(bulkId);
        setLoadingButton('transformed');
        getTransformedBulkUrl(bulkId, userId).then((url) => setDownloadUrl(url));
        setFileName(`transformed_${bulkId}.json`);
    };

    const setRawDownloadUrl = (bulkId: string, userId: string) => {
        setLoadingButtonId(bulkId);
        setLoadingButton('raw');
        getRawBulkUrl(bulkId, userId).then((url) => setRawUrl(url));
        setFileName(`raw_${bulkId}.json`);
    };

    useEffect(() => {
        if (downloadUrl !== '' || rawUrl !== '') {
            const url = downloadUrl ? downloadUrl : rawUrl;
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const blobUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(blobUrl);
                })
                .catch(console.error)
                .finally(() => {
                    setDownloadUrl('');
                    setRawUrl('');
                    setFileName('');
                    setLoadingButtonId(null);
                    setLoadingButton(null);
                });
        }
    }, [downloadUrl, rawUrl]);

    const columns = React.useMemo<GridColDef<Row>[]>(() => {
        return [
            { field: 'badgerObjectName', headerName: 'Badger Object', flex: 0.15 },
            { field: 'crmObjectName', headerName: 'CRM Object', flex: 0.15 },
            {
                field: 'userName',
                headerName: 'Requested By',
                flex: 0.15,
                renderCell: (params) => users.find((user) => user.id === params.row.requesterUserId)?.username,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 0.15,
                renderCell: (params) => <Tooltip title={params.row.errorMessage}>{params.value}</Tooltip>,
            },
            {
                field: 'processedRecords',
                headerName: 'Processed Records',
                flex: 0.15,
                renderCell: (params) => params.row.processedRecords,
            },
            {
                field: 'createdAt',
                headerName: 'Created At (UTC)',
                flex: 0.2,
                valueFormatter: (value) => {
                    const date = new Date(value as string);
                    return date.toUTCString();
                },
            },
            {
                field: 'updatedAt',
                headerName: 'Updated At (UTC)',
                flex: 0.2,
                valueFormatter: (value) => {
                    const date = new Date(value as string);
                    return date.toUTCString();
                },
            },
            {
                field: 'id',
                headerName: 'Bulk ID',
                flex: 0.1,
                renderCell: (params: GridRenderCellParams) => (
                    <strong>
                        <Tooltip title="Copy Bulk ID" arrow>
                            <IconButton onClick={() => copyIDToClipboard(params.value)}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </strong>
                ),
            },
            {
                field: 'downloadTransformed',
                headerName: 'Transformed',
                flex: 0.1,
                renderCell: (params) => (
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        size="small"
                        disabled={params.row.status !== 'COMPLETED'}
                        loading={loadingButtonId === params.row.id && loadingButton === 'transformed'}
                        onClick={() => setTransformedDownloadUrl(params.row.id as string, params.row.requesterUserId)}
                    >
                        <DownloadIcon />
                    </LoadingButton>
                ),
            },
            {
                field: 'downloadRaw',
                headerName: 'Raw',
                flex: 0.1,
                renderCell: (params) => (
                    <LoadingButton
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={
                            params.row.status === 'PENDING' ||
                            params.row.status === 'PROVIDER_IN_PROGRESS' ||
                            params.row.status === 'FAILED'
                        }
                        loading={loadingButtonId === params.row.id && loadingButton === 'raw'}
                        onClick={() => setRawDownloadUrl(params.row.id as string, params.row.requesterUserId)}
                    >
                        <DownloadIcon />
                    </LoadingButton>
                ),
            },
        ];
    }, [users, loadingButtonId]);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box
            width="100%"
            height="100%"
            m="165px"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '75vh',
                width: '85vw',
                backgroundColor: 'white',
            }}
        >
            <NewBulkDialog
                isOpen={isNewBulkOpen}
                onClose={() => setIsNewBulkOpen(false)}
                onNewBulkClose={onNewBulkClose}
                users={users}
            />
            <DataGrid
                sx={{
                    '--DataGrid-containerBackground': 'white',
                }}
                rows={bulks}
                columns={columns}
                slots={{
                    toolbar: () => (
                        <Box
                            sx={{
                                p: 1,
                                pb: 0,
                                m: 0.5,
                            }}
                        >
                            <Button
                                size="small"
                                sx={{ m: 1 }}
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={() => setIsNewBulkOpen(true)}
                            >
                                Request new bulk
                            </Button>
                            <GridToolbarQuickFilter sx={{ m: 1 }} />
                        </Box>
                    ),
                }}
                disableRowSelectionOnClick
                loading={isLoading}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                }}
            />
        </Box>
    );
};

export default BulksPage;
