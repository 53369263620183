import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MappingDTO, ObjectDTO, TemplateDTO } from '../../../types/MappingDTO';
import { createMapping, getCRMObjectNames } from '../repositories/mappingRepository';
import { storeSavedSearch } from '../repositories/netsuiteSavedSearchRepository';
import { createMappingFromTemplate, getAllTemplates } from '../repositories/templateRepository';

interface AddMappingDialogProps {
    open: boolean;
    onClose: () => void;
    onNewMapping: (mapping: MappingDTO) => void;
}

const AddMappingDialog = ({ open, onClose, onNewMapping }: AddMappingDialogProps) => {
    const { companyId } = useParams() as { companyId: string };
    const [isLoading, setIsLoading] = useState(true);
    const [savedSearch, setSavedSearch] = useState('');
    const [templates, setTemplates] = useState<TemplateDTO[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');
    const [mappingMethod, setMappingMethod] = useState<'manual' | 'template'>('manual');

    const [formData, setFormData] = useState<MappingDTO>({
        badgerName: '',
        crmName: '',
        fields: [
            {
                crmField: {
                    key: 'name_1',
                },
                badgerField: {
                    key: 'api_name_1',
                },
                type: 'string',
            },
        ],
        customJS: '// CRM to Badger\nif (fromVendor) {\n\n}\n// Badger to CRM\n else {\n\n}\ndone(transformedObject)',
    });

    const badgerObjectNameSuggestions = ['customers', 'checkins'];
    const [crmObjectNameSuggestions, setCrmObjectNameSuggestions] = useState<ObjectDTO[]>([]);

    useEffect(() => {
        if (isLoading) {
            const fetchData = async () => {
                try {
                    const objectNames = await getCRMObjectNames(companyId);
                    setCrmObjectNameSuggestions(objectNames);

                    try {
                        const templatesList = await getAllTemplates();
                        setTemplates(templatesList);
                    } catch (templateError) {
                        console.error('Error loading templates:', templateError);
                        setTemplates([]);
                        setMappingMethod('manual');
                    }

                    setIsLoading(false);
                } catch (error) {
                    console.error('Error loading data:', error);
                    setIsLoading(false);
                }
            };

            fetchData();
        }
    }, [companyId, isLoading]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            let mapping;

            if (mappingMethod === 'template' && selectedTemplate) {
                mapping = await createMappingFromTemplate(
                    companyId,
                    formData.badgerName,
                    formData.crmName,
                    selectedTemplate
                );
            } else {
                mapping = await createMapping(companyId, formData);
            }

            if (savedSearch !== '' && mapping.id) {
                await storeSavedSearch(mapping.id, savedSearch);
            }

            onNewMapping(mapping);
        } catch (error) {
            console.error('Error creating mapping:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add Mapping</DialogTitle>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <Box sx={{ width: '500px' }}>
                    <DialogContent>
                        <Autocomplete
                            id="badgerName"
                            freeSolo
                            options={badgerObjectNameSuggestions}
                            value={formData.badgerName}
                            inputValue={formData.badgerName}
                            onChange={(e, value) =>
                                setFormData({
                                    ...formData,
                                    badgerName: value || '',
                                })
                            }
                            onInputChange={(e, value) =>
                                setFormData({
                                    ...formData,
                                    badgerName: value || '',
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    autoFocus
                                    margin="dense"
                                    label="Badger Name"
                                    fullWidth
                                    placeholder="customers"
                                    required
                                />
                            )}
                        />
                        <Typography variant="body1">Maps to:</Typography>
                        <Autocomplete
                            id="crmName"
                            freeSolo
                            options={crmObjectNameSuggestions.map((option) => option.apiName)}
                            value={formData.crmName}
                            inputValue={formData.crmName}
                            onChange={(e, value) =>
                                setFormData({
                                    ...formData,
                                    crmName: value || '',
                                })
                            }
                            onInputChange={(e, value) =>
                                setFormData({
                                    ...formData,
                                    crmName: value || '',
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    label="CRM Name"
                                    placeholder="Account"
                                    fullWidth
                                    required
                                />
                            )}
                        />

                        <Box sx={{ mt: 3, mb: 2 }}>
                            <Typography variant="body1">Mapping Method:</Typography>
                            <RadioGroup
                                value={mappingMethod}
                                onChange={(e) => setMappingMethod(e.target.value as 'manual' | 'template')}
                                row
                            >
                                <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                                <FormControlLabel
                                    value="template"
                                    control={<Radio />}
                                    label="From Template"
                                    disabled={templates.length === 0}
                                />
                            </RadioGroup>
                            {templates.length === 0 && (
                                <Typography variant="caption" color="text.secondary">
                                    No templates available. Create a template first.
                                </Typography>
                            )}
                        </Box>

                        {mappingMethod === 'template' && (
                            <>
                                <Autocomplete
                                    id="templateName"
                                    options={templates.map((t) => t.templateName)}
                                    value={selectedTemplate}
                                    onChange={(e, value) => {
                                        setSelectedTemplate(value || '');
                                        if (value) {
                                            const template = templates.find((t) => t.templateName === value);
                                            if (template) {
                                                enqueueSnackbar(
                                                    `Template "${value}" selected with ${template.fields.length} fields`,
                                                    { variant: 'info' }
                                                );
                                            }
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="dense"
                                            label="Select Template"
                                            fullWidth
                                            required={mappingMethod === 'template'}
                                        />
                                    )}
                                />
                                {selectedTemplate && (
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                        {(() => {
                                            const template = templates.find((t) => t.templateName === selectedTemplate);
                                            if (template) {
                                                return `This template contains ${template.fields.length} field${
                                                    template.fields.length !== 1 ? 's' : ''
                                                }.`;
                                            }
                                            return null;
                                        })()}
                                    </Typography>
                                )}
                            </>
                        )}

                        <Typography variant="body2" sx={{ mt: 3 }}>
                            Saved Search (Netsuite only):
                        </Typography>
                        <TextField
                            id="savedSeach"
                            margin="dense"
                            label="Saved Search"
                            placeholder=""
                            fullWidth
                            value={savedSearch}
                            onChange={(e) => setSavedSearch(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} variant="outlined" color="primary">
                            Cancel
                        </Button>
                        <LoadingButton type="submit" variant="contained" color="primary" loading={isLoading}>
                            Save Mapping
                        </LoadingButton>
                    </DialogActions>
                </Box>
            </form>
        </Dialog>
    );
};

export default AddMappingDialog;
