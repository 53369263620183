import { Editor } from '@monaco-editor/react';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FieldDTO, TemplateDTO } from '../../../types/MappingDTO';
import {
    createTemplate,
    deleteTemplate,
    getAllTemplates,
    getTemplateByName,
    updateTemplate,
} from '../repositories/templateRepository';

interface TemplateManagementDialogProps {
    open: boolean;
    onClose: () => void;
}

// Generate a unique ID for new fields
const generateFieldId = () => `field_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

const TemplateManagementDialog: React.FC<TemplateManagementDialogProps> = ({ open, onClose }) => {
    const [templates, setTemplates] = useState<TemplateDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState<TemplateDTO | null>(null);
    const [templateName, setTemplateName] = useState('');
    const [customJS, setCustomJS] = useState('');
    const [fields, setFields] = useState<FieldDTO[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (open && isLoading) {
            loadTemplates();
        }
    }, [open, isLoading]);

    const loadTemplates = async () => {
        try {
            setError(null);
            const templatesList = await getAllTemplates();
            setTemplates(templatesList);
        } catch (error) {
            console.error('Error loading templates:', error);
            setError('Failed to load templates. The template service might not be available.');
            enqueueSnackbar('Error loading templates. The template service might not be available.', {
                variant: 'error',
                autoHideDuration: 5000,
            });
            setTemplates([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditTemplate = async (templateName: string) => {
        setIsLoading(true);
        try {
            const template = await getTemplateByName(templateName);
            if (template) {
                setCurrentTemplate(template);
                setTemplateName(template.templateName);
                setCustomJS(template.customJS || '');
                setFields(template.fields || []);
                setIsEditing(true);
            }
        } catch (error) {
            console.error('Error loading template details:', error);
            setError('Failed to load template details');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteTemplate = async (templateName: string) => {
        if (window.confirm(`Are you sure you want to delete the template "${templateName}"?`)) {
            setIsLoading(true);
            try {
                await deleteTemplate(templateName);
                await loadTemplates();
                enqueueSnackbar('Template deleted successfully', { variant: 'success' });
            } catch (error) {
                console.error('Error deleting template:', error);
                setError('Failed to delete template');
                setIsLoading(false);
            }
        }
    };

    const handleCreateTemplate = () => {
        setCurrentTemplate(null);
        setTemplateName('');
        setCustomJS('');
        setFields([]);
        setIsCreating(true);
    };

    const handleAddField = () => {
        setFields([
            ...fields,
            {
                id: generateFieldId(),
                crmField: { key: '' },
                badgerField: { key: '' },
                type: 'string',
            },
        ]);
    };

    const handleRemoveField = (fieldId: string | undefined) => {
        if (!fieldId) return;
        setFields(fields.filter((field) => field.id !== fieldId));
    };

    const handleUpdateField = (index: number, field: Partial<FieldDTO>) => {
        const newFields = [...fields];
        newFields[index] = { ...newFields[index], ...field };
        setFields(newFields);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!templateName.trim()) {
            setError('Template name is required');
            return;
        }

        const invalidFields = fields.filter((field) => !field.crmField.key || !field.badgerField.key);
        if (invalidFields.length > 0) {
            setError(`${invalidFields.length} field(s) have empty CRM or Badger field names`);
            return;
        }

        setIsSubmitting(true);
        setError(null);

        try {
            const templateData = {
                templateName: templateName.trim(),
                fields: fields,
                customJS,
            };

            if (isCreating) {
                await createTemplate(templateData);
                enqueueSnackbar('Template created successfully', { variant: 'success' });
            } else if (isEditing && currentTemplate) {
                await updateTemplate(currentTemplate.templateName, templateData);
                enqueueSnackbar('Template updated successfully', { variant: 'success' });
            }

            setIsCreating(false);
            setIsEditing(false);
            setCurrentTemplate(null);
            setTemplateName('');
            setCustomJS('');
            setFields([]);
            await loadTemplates();
        } catch (error: unknown) {
            console.error('Error saving template:', error);

            let errorMessage = 'Failed to save template';
            if (error) {
                if (typeof error === 'string') {
                    errorMessage = error;
                } else if (error instanceof Error) {
                    errorMessage = error.message;
                } else if (typeof error === 'object' && error !== null && 'toString' in error) {
                    errorMessage = error.toString();
                }
            }

            setError(`Failed to save template: ${errorMessage}`);
            enqueueSnackbar(`Failed to save template: ${errorMessage}`, {
                variant: 'error',
                autoHideDuration: 5000,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setIsCreating(false);
        setCurrentTemplate(null);
        setTemplateName('');
        setCustomJS('');
        setFields([]);
        setError(null);
    };

    const renderFieldEditor = () => (
        <Box sx={{ mt: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
                Field Mappings
            </Typography>

            {fields.length === 0 ? (
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    No fields defined. Add fields using the button below.
                </Typography>
            ) : (
                <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                    {fields.map((field, index) => (
                        <Grid container spacing={2} key={field.id || index} sx={{ mb: 2 }}>
                            <Grid item xs={4.5}>
                                <TextField
                                    fullWidth
                                    label="Badger Field"
                                    value={field.badgerField.key}
                                    onChange={(e) => handleUpdateField(index, { badgerField: { key: e.target.value } })}
                                    disabled={isSubmitting}
                                    size="small"
                                    required
                                    error={!field.badgerField.key}
                                />
                            </Grid>
                            <Grid item xs={4.5}>
                                <TextField
                                    fullWidth
                                    label="CRM Field"
                                    value={field.crmField.key}
                                    onChange={(e) => handleUpdateField(index, { crmField: { key: e.target.value } })}
                                    disabled={isSubmitting}
                                    size="small"
                                    required
                                    error={!field.crmField.key}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id={`field-type-label-${index}`}>Type</InputLabel>
                                    <Select
                                        labelId={`field-type-label-${index}`}
                                        value={field.type}
                                        label="Type"
                                        onChange={(e) => handleUpdateField(index, { type: e.target.value })}
                                        disabled={isSubmitting}
                                    >
                                        <MenuItem value="string">String</MenuItem>
                                        <MenuItem value="number">Number</MenuItem>
                                        <MenuItem value="boolean">Boolean</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Remove Field">
                                    <IconButton
                                        onClick={() => handleRemoveField(field.id)}
                                        disabled={isSubmitting}
                                        color="error"
                                        size="small"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                </Paper>
            )}

            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddField} disabled={isSubmitting}>
                Add Field
            </Button>
        </Box>
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                {isEditing ? 'Edit Template' : isCreating ? 'Create Template' : 'Template Management'}
            </DialogTitle>

            <DialogContent>
                {isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" p={3}>
                        <CircularProgress />
                    </Box>
                ) : isEditing || isCreating ? (
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="templateName"
                            label="Template Name"
                            name="templateName"
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            disabled={isSubmitting}
                        />

                        {renderFieldEditor()}

                        <Typography variant="h6" gutterBottom>
                            Custom JavaScript
                        </Typography>
                        <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 1 }}>
                            <Editor
                                height="300px"
                                defaultLanguage="javascript"
                                value={customJS}
                                onChange={(value) => setCustomJS(value || '')}
                                options={{
                                    minimap: { enabled: false },
                                    scrollBeyondLastLine: false,
                                    readOnly: isSubmitting,
                                }}
                            />
                        </Box>

                        {error && (
                            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                                {error}
                            </Typography>
                        )}
                    </Box>
                ) : (
                    <>
                        <Box display="flex" justifyContent="flex-end" mb={2}>
                            <Button
                                startIcon={<AddIcon />}
                                variant="contained"
                                color="primary"
                                onClick={handleCreateTemplate}
                            >
                                Create Template
                            </Button>
                        </Box>

                        {templates.length === 0 ? (
                            <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                                No templates found. Create your first template.
                            </Typography>
                        ) : (
                            <List>
                                {templates.map((template) => (
                                    <React.Fragment key={template.templateName}>
                                        <ListItem>
                                            <ListItemText
                                                primary={template.templateName}
                                                secondary={`Fields: ${template.fields.length}`}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="edit"
                                                    onClick={() => handleEditTemplate(template.templateName)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => handleDeleteTemplate(template.templateName)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                            </List>
                        )}

                        {error && (
                            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                                {error}
                            </Typography>
                        )}
                    </>
                )}
            </DialogContent>

            <DialogActions>
                {isEditing || isCreating ? (
                    <>
                        <Button onClick={handleCancel} disabled={isSubmitting}>
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={handleSubmit}
                            loading={isSubmitting}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </LoadingButton>
                    </>
                ) : (
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default TemplateManagementDialog;
